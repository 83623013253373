import React from 'react';
import { useHistory, Link } from 'react-router-dom'
import { Tag, Menu, Dropdown, Button } from 'antd'
import './Tags.css'
import { DELETE_TAG, CLOSE_ALL, CLOSE_OTHER } from '../../store/actionTypes'
import { useStore } from '../../store/context'
import { RouteList } from '../../route/Index';
import { dropByCacheKey } from 'react-router-cache-route';

const Tags: React.FC<any> = () => {
  const [store, dispatch] = useStore()
  const history = useHistory()
  const clickMenu = (param: any) => {
    switch (param.key) {
      case 'closeAll':
        history.push('/customer')
        dispatch({type: CLOSE_ALL})
        break;
      case 'closeOther':
        dispatch({type: CLOSE_OTHER, value: history.location.pathname})
        break;
    }
  }
  const menu = (
    <Menu onClick={clickMenu} className="tx_c">
      <Menu.Item key="closeAll">关闭所有</Menu.Item>
      <Menu.Item key="closeOther">关闭其他</Menu.Item>
    </Menu>
  )
  const onCloseTag = (index: number) => { // 关闭tag
    if (history.location.pathname === store.tags[index].pathname) {
      dropByCacheKey(store.tags[index].pathname)
      store.tags.length ? history.push(store.tags[index-1].pathname) : history.push('/')
    }
    dispatch({type: DELETE_TAG, value: index})
  }

  return (
    <div className="bgf bor_t bor_b flex_just_betw flex_cen">
      <div className="tags_box p10">
        {
          store.tags.map((it: any,index:number) => (
            RouteList.map((item: any) => (
              it?.pathname === item.path ? 
              item.name === 'Home' || store.tags.length === 1 ?
              <Tag color={history.location.pathname === item.path ? 'blue' : ''} key={item.name}>
                <Link to={item.path}>{item.title}</Link>
              </Tag> :
              <Tag color={history.location.pathname === item.path ? 'blue' : ''} closable onClose={() => onCloseTag(index)} key={item.name}>
                <Link to={item.path}>{item.title}</Link>  
              </Tag> : null
            ))
          ))
        }
        {/* {
          store.tags.length && store.tags.map((item: any, index: number) => (
            item.name === 'Home' ?
            <Tag color={history.location.pathname === item.path ? 'blue' : ''} key={item.name}>
              <Link to={item.path}>{item.title}</Link>
            </Tag> :
            <Tag color={history.location.pathname === item.path ? 'blue' : ''} closable onClose={() => onCloseTag(index)} key={item.name}>
              <Link to={item.path}>{item.title}</Link>  
            </Tag>
          ))
        } */}
      </div>
      <div className="pr10">
        <Dropdown overlay={menu}>
          <Button type="primary">标签选项</Button>
        </Dropdown>
      </div>
    </div>
  )
}

export default Tags