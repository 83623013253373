import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import  { CacheSwitch } from 'react-router-cache-route'
import './App.css';
import { Provider } from './store/context'
import Login from './pages/Login/login'
import Layout from './pages/Layout/Layout'
import NoPermissions from './pages/error/NoPermissions'
import { checkMobile } from './utils//common'

const App: React.FC<any> = () => {
  useEffect(() => {
    checkMobile()
  })

  return (<Provider>
    <Router> 
      <div className="App">
        <CacheSwitch>
          <Route path="/NoPermissions" component={NoPermissions} />
          <Route path="/login" component={Login} />
          <Route path="/" component={Layout} />
        </CacheSwitch>
      </div>
    </Router>
  </Provider>
)}

export default App;